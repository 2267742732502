import { mapHelper } from "@/utils/common.js";
// 成功or 失败
const isSucceedStatus = [
  {
    value: 1,
    label: "成功",
  },
  {
    value: 0,
    label: "失败",
  },
  {
    value: -1,
    label: "未上报",
  },
];
const { map: isSucceedStatusMap, setOps: isSucceedStatusOps } =
  mapHelper.setMap(isSucceedStatus);
// 绑定状态
const bindingState = [
   {
    value: 0,
    label: "未绑定",
  },
  {
    value: 1,
    label: "已绑定",
  },
 
];
const { map: bindingStateMap, setOps: bindingStateOps } =
  mapHelper.setMap(bindingState);

export { isSucceedStatusOps,bindingStateMap,bindingStateOps };
