//数据类型
const getFieldTypes = `/gateway/hc-portal/szdz/fieldTypes`;
//租户及社区编码列表
const getTenantList = `/gateway/hc-portal/szdz/tenantList`;
//小区编码列表
const getComplexList = `/gateway/hc-portal/szdz/complexList`;
//列表查询
const getPageListUrl = `/gateway/hc-portal/szdz/page`;
//编辑
const editDataUrl = `/gateway/hc-portal/szdz/edit`;
//详情
const getDataInfo = `/gateway/hc-portal/szdz/info`;
//新增
const getAddData = `/gateway/hc-portal/szdz/addData`;
//上报
const getPushDataUrl = `/gateway/hc-portal/szdz/pushData`;
//全部上报
const pushDataBatch = `/gateway/hc-portal/szdz/pushDataBatch`;
//删除
const deleteDataUrl = `/gateway/hc-portal/szdz/delete`;
//物联设备列表
const iotDeviceList = `/gateway/hc-portal/szdz/iotDeviceList`
export {
  getFieldTypes,
  getTenantList,
  getPageListUrl,
  editDataUrl,
  getComplexList,
  getDataInfo,
  getAddData,
  getPushDataUrl,
  deleteDataUrl,
  iotDeviceList,
  pushDataBatch,
};
