<template>
  <div class="reportPartyMemberInfo">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :postData="searchParam"
      :isOperateColumn="true"
      methods="post"
    >
      <template #headBtnSlot>
        <v-button text="返回" @click="$router.back()"></v-button>
      </template>
      <template #searchSlot>
        <v-input
          v-model="searchParam.resourceName"
          label="设备名称："
          placeholder="请输入"
        />
        <v-select
          clearable
          v-model="searchParam.isBoundOpsSzdz"
          label="绑定状态："
          :options="bindingStateOps"
          placeholder="请选择"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="绑定"
          :disabled="scope.row.isBoundOpsSzdz == 1"
          type="text"
          @click="bindingData(scope.row)"
        />
      </template>
    </v-list>
    <v-dialog
      title="绑定"
      v-model="isShowDialog"
      :close-on-click-modal="false"
      @confirm="confirm"
      :center="false"
      sureTxt="确认"
    >
      <form-panel
        ref="formPanel"
        :form="dialogForm"
        :hasHeader="false"
        :footerShow="false"
      >
        <el-form-item
          label="社区列表"
          :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
          prop="futureCommunityCode"
        >
          <v-select
            :options="futureCommunityCode"
            v-model="dialogForm.futureCommunityCode"
          ></v-select>
        </el-form-item>
        <el-form-item
          label="小区名称"
          :rules="[
            { required: true, message: '请选择完成截止日期', trigger: 'blur' },
          ]"
        >
          <v-select
            :options="complexCode"
            v-model="dialogForm.complexCode"
          ></v-select>
        </el-form-item>
      </form-panel>
    </v-dialog>
  </div>
</template>

<script>
import {
  iotDeviceList,
  getTenantList,
  getComplexList,
  getAddData,
} from "./api.js";
import { bindingStateOps, bindingStateMap } from "./map.js";
import { createReportStatusHtml } from "@/utils/utils.js";
import { _localStorage } from "@/utils/utils.js";
import { mapState } from "vuex";
export default {
  name: "reportPartyMemberInfo",
  components: {},
  props: {},
  data() {
    return {
      id: "",
      isShowDialog: false,
      bindingStateOps: bindingStateOps(),
      searchParam: {
        tenantId: "",
        resourceName: "", //设备名称
        // isBoundOpsSzdz: null,
      },
      dialogForm: {
        futureCommunityCode: "",
        complexCode: "",
      },
      futureCommunityCode: [], //获取社区编码列表
      complexCode: [], //获取小区编码列表
      headers: [
        {
          prop: "resourceName",
          label: "设备名称",
          show: true,
          formatter: (row, prop) => {
            return row[prop] ? row[prop] : "--";
          },
        },
        {
          prop: "productName",
          label: "产品名称",
          show: true,
        },
        {
          prop: "deviceSerialNum",
          label: "序列号",
          show: true,
        },
        {
          prop: "deviceIp",
          label: "IP",
          show: true,
          formatter: (row, prop) => {
            return row[prop] ? row[prop] : "--";
          },
        },
        {
          prop: "status",
          label: "状态",
          show: true,
          formatter: (row, prop) => {
            let text = "";
            if (row[prop] == 0) {
              text = "离线";
            } else if (row[prop] == 1) {
              text = "在线";
            } else {
              text = "--";
            }
            return text;
          },
        },
        {
          prop: "isBoundOpsSzdz",
          label: "是否绑定",
          show: true,
          formatter: (row, prop) => {
            return bindingStateMap[row[prop]];
          },
        },
      ],
      tableUrl: iotDeviceList,
      submitData: {},
    };
  },
  async created() {
    if (_localStorage.getItem("userInfo")) {
      let userInfo = JSON.parse(_localStorage.getItem("userInfo"));
      this.$store.commit("app/setAccountInfo", userInfo);
      this.searchParam.tenantId = userInfo.tenantId;
    }
    this.getTenantList();
    this.getComplexList();
  },
  computed: {
    ...mapState("app", ["accountInfo"]),
  },
  mounted() {},
  methods: {
    //确认
    confirm() {
      const result = this.$refs.formPanel.validate();
      if (result) {
        this.submitData.futureCommunityCode =
          this.dialogForm.futureCommunityCode;
        this.submitData.complexCode = this.dialogForm.complexCode;
        delete this.submitData.id;
        delete this.submitData.insertTime;
        delete this.submitData.reportStatus;
        delete this.submitData.updateTime;
        const params = {
          mapper: "OpsSzdzEquipmentResourceMapper",
          data: this.submitData,
        };
        this.$axios.post(getAddData, params).then((res) => {
          if (res.code == 200) {
            this.$refs.list.getList();
            this.$message.success(res.msg);
          }
          this.isShowDialog = false;
        });
      }
    },
    //获取小区编码列表
    async getComplexList() {
      const params = {
        tenantId: this.accountInfo.tenantId,
      };
      const res = await this.$axios.post(
        getComplexList,
        this.$qs.stringify(params)
      );
      if (res.code == 200) {
        const { data } = res;
        data.forEach((item) => {
          item.value = item.houseCode;
          item.label = item.name;
        });
        this.complexCode = data;
      }
    },
    //获取社区编码列表
    async getTenantList() {
      const params = {
        tenantId: this.accountInfo.tenantId,
      };
      const res = await this.$axios.post(
        getTenantList,
        this.$qs.stringify(params)
      );
      if (res.code == 200) {
        const { data } = res;

        data.forEach((item) => {
          item.value = item.futureCommunityCode;
          item.label = item.tenantName;
        });
        this.futureCommunityCode = data;
      }
    },
    //绑定
    bindingData(row) {
      (this.dialogForm = {
        futureCommunityCode: "",
        complexCode: "",
      }),
        (this.submitData = row);
      this.isShowDialog = true;
    },
  },
};
</script>

<style scoped lang="less">
.reportPartyMemberInfo {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  ::v-deep .hc-list {
    flex: 1;
    .oTable {
      padding: 34px;
    }
    // .search {
    //   background-color: #fff;
    //   box-sizing: border-box;
    //   padding: 20px 65px;
    //   margin-top: 10px;
    //   position: relative;
    //   .tag {
    //     cursor: pointer;
    //     box-sizing: border-box;
    //     color: rgba(0, 0, 0, 0.85);
    //     font-size: 14px;
    //     position: absolute;
    //     left: -8px;
    //     top: 8px;
    //     padding: 5px;
    //     color: #fff;
    //     white-space: nowrap;
    //     background-color: #1f5ad8;
    //     border-radius: 2px;
    //     border-bottom-right-radius: 0;
    //     &::after {
    //       content: "";
    //       position: absolute;
    //       left: 0;
    //       top: 100%;
    //       width: 0;
    //       height: 0;
    //       border-color: #bfbfbf transparent;
    //       border-width: 8px 0 0 8px;
    //       border-style: solid;
    //     }
    //   }
    // }
  }
  ::v-deep .el-dialog__body {
    padding: 10px 20px;
  }
  ::v-deep .form-panel-container {
    width: 100%;
    margin: 0;
    .content {
      width: 100%;
      justify-content: flex-start;
      .el-form {
        width: 100%;
        .el-form-item {
          width: 100%;
          display: flex;
          .el-form-item__label {
            text-align: right;
          }
          .el-form-item__content {
            margin: 0 !important;
            flex: 1;
            width: 90%;
            .v-control {
              width: 100%;
              .el-select {
                width: 100% !important;
                .el-input {
                  width: 100% !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
